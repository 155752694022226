.carousel-control-next-icon {
    color: #000000
};

.carousel-control-prev-icon {
    color: #000000
};

* {
    margin: 25%;
};

.carousel-image{
    
}
