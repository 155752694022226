.App {
  text-align: center;
  font-family: "Lato", sans-serif;
}

.App-title {
  margin: 30px 0px 30px 0px;
  text-transform: uppercase;
  letter-spacing: 20px;
  font-family: "Cinzel Decorative", cursive;
  font-size: 75px;
}

.App-description {
  margin-bottom: 20px;
  font-size: 16px;
}

.plabel {
  margin: 10px 0px 10px 0px;
}
.Comment {
  margin-top: 40px;
}
.Comment-title {
  padding-top: 30px;
}

.btn-custom {
  color: white;
  background: rgb(237, 59, 75);
  border-width: 1px 1px 1px 1px;
}

@media only screen and (max-width: 1000px) {
  .App-title {
    font-size: 60px;
  }

  .App-description {
    margin-bottom: 20px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .App-title {
    font-size: 50px;
  }

  .App-description {
    margin-bottom: 20px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 750px) {
  .App-title {
    font-size: 40px;
  }

  .App-description {
    margin-bottom: 20px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .App-title {
    font-size: 30px;
  }

  .App-description {
    margin-bottom: 20px;
    font-size: 10px;
  }
}
